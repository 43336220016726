

















































































































































































.design-scale-pay-form-item{
  display: flex;
  padding-left:20px;

  .el-form-item__label{
    width: 40%;
  }
  .el-form-item__content{
    width:60%;
    padding-right: 20px;
    margin-bottom: 20px;
  }
}
